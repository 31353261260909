import { createApp } from 'vue';
import { createLogger } from 'vue-logger-plugin';
import { components } from './components';
import { ComponentDefinition } from './common/models/app/component-definition.model';
import { DeviceService } from './services/device.service';
import { UtilsService } from './services/utils.service';
import { AdmiralService } from './services/admiral.service';
import { GTMService } from './services/gtm.service';
import { GAService } from './services/ga.service';
import { FetchService } from './services/fetch.service';
import { NewslettersService } from './services/newsletters.service';
import { LocationService } from './services/location.service';
import { NewsletterManageService } from './services/newslettermanage.service';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { useIdentityStore } from './stores/identity-store';
import { useSessionStore } from './stores/SessionStorage';

declare global {
    interface Window {
        dataLayer: any;
        ga: any;
        jwplayer: any;
        grecaptcha: any;
        CaptchaCallback: any;
        Pushnami: any;
        rad: any;
        admiral: any;
        gtag: any;
    }
}

// configure logger
const logger = createLogger({
    enabled: true,
    level: process.env.NODE_ENV == 'production' ? 'error' : 'debug',
    beforeHooks: [],
    afterHooks: []
});

// Events are filtered for these strings in the error message
const eventLoggingExclusions = [
    'ChunkLoadError',
    'SecurityError',
    'TypeError'
];

// configure state management
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

//  Create Service Instances
const deviceService = new DeviceService(logger);
const utilsService = new UtilsService(logger);
const gtmService = new GTMService(logger);
const gaService = new GAService(logger);
const admiralService = new AdmiralService(logger, utilsService);
const fetchService = new FetchService();
const newslettersService = new NewslettersService(logger, fetchService);
const newsletterManageService = new NewsletterManageService(logger, fetchService);
const locationService = new LocationService(logger, fetchService);

// Load components present on the page as individual apps
components.forEach((config: ComponentDefinition) => {
    // find all elements that will use this component
    const elements = Array.from(document.getElementsByTagName(config.tag));

    // initiate each instance of those elements with the component as their own app instance
    elements.forEach((elem: Element) => {
        // make sure elem exists
        if(elem == null) {
            logger.debug(`Component ${ config.tag } is no longer accessible. Perhaps a parent element loaded instead.`);
            return;
        }

        // make sure this element isn't a child of another custom element
        let walker = elem.parentElement;
        let limit = 50;
        while(
            !!walker &&
            limit > 0 &&
            walker.tagName?.toLowerCase() !== 'body' &&
            walker.tagName?.toLowerCase().substr(0, 4) !== 'rad-'
        ) {
            walker = walker.parentElement;
            limit--;
        }
        if(!!walker && walker.tagName?.toLowerCase().substr(0, 4) === 'rad-') {
            logger.debug(`Component ${ config.tag } is nested under ${ walker.tagName } and will not be loaded again.`);
            return;
        }

        logger.debug(`Loading Component ${ config.tag }`);
        const app = createApp({});

        // register logger
        app.use(logger);

        // register pinia state management
        app.use(pinia);

        // Set Up Identity Store
        const identity = useIdentityStore();
        if(!identity.initialized) {
            const sessionStore = useSessionStore();
            sessionStore.init();
            identity.init(logger).then(() => logger.debug('Identity Store Setup Complete'));
        }

        // add services for injection
        app.provide('DeviceService', deviceService);
        app.provide('UtilsService', utilsService);
        app.provide('AdmiralService', admiralService);
        app.provide('GTMService', gtmService);
        app.provide('GAService', gaService);
        app.provide('FetchService', fetchService);
        app.provide('NewslettersService', newslettersService);
        app.provide('NewsletterManageService', newsletterManageService);
        app.provide('LocationService', locationService);

        // register all components with app
        components.forEach((comp) => app.component(comp.tag, comp.component));

        // wrap element in container before mounting to ensure props get picked up
        if(elem.parentNode) {
            // create app container
            const container = document.createElement('rad-app-container');
            // insert container before target element
            elem.parentNode.insertBefore(container, elem);
            // move target element into container
            // note this "eats" the element so it is no longer fetch-able from getElementsByTagName
            container.appendChild(elem);

            logger.debug(`Mounting component ${ config.tag }`);

            // mount to container
            app.mount(container);
        } else {
            // report to console
            logger.error(`Failed to mount component ${ config.tag }`);
        }
    });
});
