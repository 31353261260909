import { defineAsyncComponent } from 'vue';
import { ComponentDefinition } from '../common/models/app/component-definition.model';

// Register components
export const components: Array<ComponentDefinition> = [
    {
        tag: 'rad-navigation-link-topic',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-navigation-link-topic" */ `./navigation/link/Topics.vue`))
    },
    {
        tag: 'rad-share-v1',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-share-v1" */ `./social/ShareV1.vue`))
    },
    {
        tag: 'rad-affix',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-affix" */ `./structure/Affix.vue`))
    },
    {
        tag: 'rad-single-picture-modal',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-single-picture-modal" */ `./structure/SinglePictureModal.vue`))
    },
    {
        tag: 'rad-folder-library-content-collapse',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-folder-library-content-collapse" */ `./folder/LibraryContentCollapse.vue`))
    },
    {
        tag: 'rad-carousel-docsology',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-carousel-docsology" */ `./carousel/Docsology.vue`)
        )
    },
    {
        tag: 'rad-carousel-docsology-card',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-carousel-docsology-card" */ `./carousel/Docsology-Card.vue`)
        )
    },
    {
        tag: 'rad-carousel-testimonial',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-carousel-testimonial" */ `./carousel/Testimonial.vue`)
        )
    },
    {
        tag: 'rad-carousel-testimonial-card',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-carousel-testimonial-card" */ `./carousel/Testimonial-Card.vue`)
        )
    },
    {
        tag: 'rad-carousel-book',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-carousel-book" */ `./carousel/Book.vue`)
        )
    },
    {
        tag: 'rad-carousel-book-card',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-carousel-book-card" */ `./carousel/Book-Card.vue`)
        )
    },
    {
        tag: 'rad-admiral-login',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-admiral-login" */ `./admiral/Login.vue`))
    },
    {
        tag: 'rad-tagged-with',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-tagged-with" */ `./structure/TaggedWith.vue`)
        )
    },
    {
        tag: 'rad-jwplayer',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-jwplayer" */ `./jwplayer/JWPlayer.vue`))
    },
    {
        tag: 'rad-contact-us',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-contact-us" */ `./emails/ContactUs.vue`))
    },
    {
        tag: 'rad-google-recaptcha',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-google-recaptcha" */ `./google/ReCaptcha.vue`)
        )
    },
    {
        tag: 'rad-library-cards',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-library-cards" */ `./library/Cards.vue`))
    },
    {
        tag: 'rad-library-cards-nav',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-library-cards-nav" */ `./library/CardsNav.vue`)
        )
    },
    {
        tag: 'rad-library-cards-square',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-library-cards-square" */ `./library/CardsSquare.vue`)
        )
    },
    {
        tag: 'rad-library-glossary',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-library-glossary" */ `./library/Glossary.vue`)
        )
    },
    {
        tag: 'rad-country-selector',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-country-selector" */ `./country/Selector.vue`)
        )
    },
    {
        tag: 'rad-country-selector-index',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-country-selector-index" */ `./country/SelectorIndex.vue`)
        )
    },
    {
        tag: 'rad-about-us',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-about-us" */ `./about-us.vue`))
    },
    {
        tag: 'rad-navigation-sidebar',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-navigation-sidebar" */ `./navigation/sidebar/Sidebar.vue`)
        )
    },
    {
        tag: 'rad-navigation-sidebar-collapsible',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-navigation-sidebar-collapsible" */ `./navigation/sidebar/SidebarCollapsible.vue`
                )
        )
    },
    {
        tag: 'rad-navigation-sidebar-subnav',
        component: defineAsyncComponent(
            () =>
                import(/* webpackChunkName: "rad-navigation-sidebar-subnav" */ `./navigation/sidebar/SidebarSubNav.vue`)
        )
    },
    {
        tag: 'rad-navigation-sidebar-subnav-more',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-navigation-sidebar-subnav-more" */ `./navigation/sidebar/SidebarSubNavMore.vue`
                )
        )
    },
    {
        tag: 'rad-newsletters-signup-wide',
        component: defineAsyncComponent(
            () =>
                import(/* webpackChunkName: "rad-newsletters-signup-wide" */ `./newsletters/NewslettersSignupWide.vue`)
        )
    },
    {
        tag: 'rad-newsletters-signup-wide-more-like-this',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-newsletters-signup-wide-more-like-this" */ `./newsletters/NewsletterSignupWideMoreLikeThis.vue`
                )
        )
    },
    {
        tag: 'rad-newsletters-author-narrow-signup',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-newsletters-author-narrow-signup" */ `./newsletters/AuthorNarrowSignup.vue`
                )
        )
    },
    {
        tag: 'rad-newsletters-narrow-signup',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-newsletters-narrow-signup" */ `./newsletters/NarrowSignup.vue`)
        )
    },
    {
        tag: 'rad-newsletters-single-narrow-signup',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-newsletters-single-narrow-signup" */ `./newsletters/SingleNarrowSignup.vue`
                )
        )
    },
    {
        tag: 'rad-newsletters-single-wide-signup',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-newsletters-single-wide-signup" */ `./newsletters/SingleWideSignup.vue`
                )
        )
    },
    {
        tag: 'rad-newsletters-document-header-signup',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-newsletters-document-header-signup" */ `./newsletters/DocumentHeaderSignup.vue`
                )
        )
    },
    {
        tag: 'rad-newsletters-wordpress-simple',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-newsletters-wordpress-simple" */ `./newsletters/WordpressSimple.vue`)
        )
    },
    {
        tag: 'rad-newsletters-wordpress-narrow',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-newsletters-wordpress-narrow" */ `./newsletters/WordpressNarrow.vue`)
        )
    },
    {
        tag: 'rad-newsletters-wordpress-simple-channel',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-newsletters-wordpress-simple-channel" */ `./newsletters/WordpressSimpleChannel.vue`
                )
        )
    },
    {
        tag: 'rad-newsletters-wordpress-collapsable',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-newsletters-wordpress-collapsable" */ `./newsletters/WordpressCollapsable.vue`
                )
        )
    },
    {
        tag: 'rad-newsletters-wordpress-horizontal',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-newsletters-wordpress-horizontal" */ `./newsletters/WordpressHorizontal.vue`
                )
        )
    },
    {
        tag: 'rad-newsletters-wordpress-newsletter',
        component: defineAsyncComponent(
            () =>
                import(
                    /* webpackChunkName: "rad-newsletters-wordpress-newsletter" */ `./newsletters/WordpressNewsletter.vue`
                )
        )
    },
    { 
        tag: 'rad-newsletter-simple-signup', 
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-newsletter-simple-signup" */ `./newsletters/SimpleSignup.vue`)
        ) 
    },
    { 
        tag: 'rad-newsletter-content-simple-signup', 
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-newsletter-content-simple-signup" */ `./newsletters/ContentSimpleSignup.vue`)
        ) 
    },
    {
        tag: 'rad-search-filter',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-search-filter" */ `./structure/SearchFilter.vue`)
        )
    },
    {
        tag: 'rad-search-filter2',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-search-filter2" */ `./structure/SearchFilter2.vue`)
        )
    },
    {
        tag: 'rad-read-more',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-read-more" */ `./editorial/ReadMore.vue`)
        )
    },
    {
        tag: 'rad-rss-search-filter', 
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-rss-search-filter" */ `./rss/RssSearchFilter.vue`)
        ) 
    },
    {
        tag: 'rad-rss-search-filter-blog', 
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-rss-search-filter-blog" */ `./rss/RssSearchFilterBlog.vue`)
        ) 
    },
    {
        tag: 'rad-rss-search-filter-channel', 
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-rss-search-filter-channel" */ `./rss/RssSearchFilterChannel.vue`)
        ) 
    },
    {
        tag: 'rad-rss-search-filter-channel-nav', 
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-rss-search-filter-channel-nav" */ `./rss/RssSearchFilterChannelNav.vue`)
        ) 
    },
    {
        tag: 'rad-navigation-subnav-button', 
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-navigation-subnav-button" */ `./navigation/subnav/Button.vue`)
        ) 
    },
    {
        tag: 'rad-newsletter-signup-manage',
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-newsletter-signup-manage" */ `./newsletters/signup/Manage.vue`)
        )
    },
    { 
        tag: 'rad-newsletter-retention', 
        component: defineAsyncComponent(
            () => import(/* webpackChunkName: "rad-newsletter-retention" */ `./newsletters/signup/NewsletterRetention.vue`)
        ) 
    },
    {
        tag: 'rad-newsletter-signup-subscribe',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-newsletter-signup-subscribe" */ `./newsletters/signup/Subscribe.vue`))
    },
    {
        tag: 'rad-toggle-button',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-toggle-button" */ `./structure/ToggleButton.vue`))
    },
    {
        tag: 'rad-top-100-modal',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-top-100-modal" */ `./top100/Top100Modal.vue`))
    },
    {
        tag: 'rad-quiz-onepage',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-onepage" */ `./quiz/OnePage.vue`))
    },
    {
        tag: 'rad-quiz-onepage-question',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-onepage-question" */ `./quiz/onepage/Question.vue`))
    },
    {
        tag: 'rad-quiz-onepage-answer',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-onepage-answer" */ `./quiz/onepage/Answer.vue`))
    },
    {
        tag: 'rad-quiz-onepage-result',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-quiz-onepage-result" */ `./quiz/onepage/Result.vue`))
    },
    {
        tag: 'rad-content-link',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "rad-content-link" */ `./content/link.vue`))
    }
];
